import {
  ADD_SCENARIO,
  CREATE_ANSWER,
  CREATE_QUESTION,
  DELETE_ANSWER,
  DELETE_QUESTION,
  GET_SCENARIO,
  SCENARIO_ERROR,
  SCENARIO_LOADING,
  UPDATE_ACTION,
  UPDATE_ANSWER,
  UPDATE_QUESTION,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  questions: [],
  scenarios: {},
};

const scenarioReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SCENARIO_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(SCENARIO_ERROR, (state, { payload }) => {
      state.data = [];
      state.questions = [];
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_SCENARIO, (state, { payload }) => {
      state.error = "";
      state.questions = payload.questions;
      delete payload.questions;
      state.data = payload;
      state.loading = false;
    })
    .addCase(ADD_SCENARIO, (state, { payload }) => {
      state.scenarios = payload;
      state.loading = false;
    })
    .addCase(CREATE_QUESTION, (state, { payload }) => {
      state.questions.push(payload);
      const questionFound = state.questions.findIndex(
        (e) => e.id === payload?.id
      );
      state.questions[questionFound].answers = [];
    })
    .addCase(UPDATE_QUESTION, (state, { payload }) => {
      const questionFound = state.questions.filter(
        (e) => e.id === payload?.id
      )[0];
      const index = state.questions.indexOf(questionFound);
      state.questions[index] = payload;
    })
    .addCase(CREATE_ANSWER, (state, { payload }) => {
      const questionFound = state.questions.findIndex(
        (e) => e.id === payload?.questionId
      );
      state.questions[questionFound].answers.push(payload.answer);
    })
    .addCase(DELETE_ANSWER, (state, { payload }) => {
      const questionFound = state.questions.filter(
        (e) => e.id === payload?.questionId
      )[0];
      questionFound.answers = questionFound.answers.filter(
        (e) => e?.id !== payload?.answerId
      );
      const index = state.questions.indexOf(questionFound);
      state.questions[index] = questionFound;
    })
    .addCase(UPDATE_ANSWER, (state, { payload }) => {
      const questionFound = state.questions.filter(
        (e) => e.id === payload?.questionId
      )[0];

      const answerFound = questionFound.answers.filter(
        (e) => e?.id === payload?.answer?.id
      )[0];

      const answerIndex = questionFound.answers.indexOf(answerFound);
      questionFound.answers[answerIndex] = payload?.answer;

      const questionIndex = state.questions.indexOf(questionFound);
      state.questions[questionIndex] = questionFound;
    })

    .addCase(UPDATE_ACTION, (state, { payload }) => {
      const questionFound = state.questions.filter(
        (e) => e.id === payload?.questionId
      )[0];

      const answerFound = questionFound.answers.filter(
        (e) => e?.id === payload?.answer?.answerId
      )[0];

      const answerIndex = questionFound.answers.indexOf(answerFound);
      console.log(
        "ef",
        payload?.answer?.answerId,
        questionFound.answers.indexOf(answerFound),
        questionFound.answers[answerIndex]
      );
      questionFound.answers[answerIndex].action = payload?.answer;

      const questionIndex = state.questions.indexOf(questionFound);
      state.questions[questionIndex] = questionFound;
    })
    .addCase(DELETE_QUESTION, (state, { payload }) => {
      state.questions = state.questions.filter((e) => e.id !== payload);
    });
});

export default scenarioReducer;
