export const GET_SCENARIO = "GET_SCENARIO";
export const SCENARIO_LOADING = "SCENARIO_LOADING";
export const SCENARIO_ERROR = "SCENARIO_ERROR";
export const ADD_SCENARIO = "ADD_SCENARIO";

export const CREATE_QUESTION = "CREATE_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";

export const CREATE_ANSWER = "CREATE_ANSWER";
export const DELETE_ANSWER = "DELETE_ANSWER";
export const UPDATE_ANSWER = "UPDATE_ANSWER";

export const ANSWER_LOADING = "ANSWER_LOADING";
export const ANSWER_ERROR = "ANSWER_ERROR";
export const GET_ANSWER = "GET_ANSWER";
export const UPDATE_ACTION = "UPDATE_ACTION";

export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTHENTICATED = "AUTHENTICATED";
