import { ANSWER_LOADING, ANSWER_ERROR, GET_ANSWER } from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const answerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ANSWER_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(ANSWER_ERROR, (state, { payload }) => {
      state.data = [];
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_ANSWER, (state, { payload }) => {
      state.error = "";
      state.data = payload;
      state.loading = false;
    });
});

export default answerReducer;
